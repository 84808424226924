
function card() {
	var copayDownload = jQuery('.copay');
	copayDownload.click(function(e){
		e.preventDefault();
		var brand = jQuery(this).data('brand');
		getCard(brand);
	});
}


function getCard(card) {
    var newWindow = window.open();
    var download;
    jQuery('.errDownload').html('');
	jQuery.post("https://copay.medthink.com/card", {brand: card}, function(data){
        console.log(data.number);
        download = data.pdf;
        newWindow.location = download;
        jQuery('.errDownload').append('<p>If your download doesn\'t start automatically, <a class="teal dark" href="' + download + '" target="_blank">click here to download.</a>');
    });
}


function initSavings() {
    console.log('init savings')
    var baseUrl = 'https://copay.medthink.com/horizant/card';
    jQuery( "#activate-card" ).focusout(function() {
        submitActivate();
    });



      jQuery('.card-dl-link').attr('href', 'https://copay.medthink.com' + decodeURIComponent(getParameterByName('pdf')));
      jQuery('.card-dl-link').click();
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function submitReplace()
{
    var baseUrl = 'https://copay.medthink.com/horizant/card';
    jQuery('#replaceForm .form-submit-error').html("");
    var data = jQuery('#replaceForm').serializeArray();
    //strip phone formatting
    var phoneIndex = data.findIndex(x => x.name === 'phone')
    data[phoneIndex].value = data[phoneIndex].value.replace(/\D+/g, "");

    //fix date format
    var dobIndex = data.findIndex(x => x.name === 'dob');
    var year = data[dobIndex].value.split('-')[0];
    var month = data[dobIndex].value.split('-')[1];
    var day = data[dobIndex].value.split('-')[2];
    data[dobIndex].value = month + '/' + day + '/' + year;
    
    console.log(data)
    //check validity
    if(jQuery("#replaceForm")[0].checkValidity())
    {
        //submit
        jQuery.ajax({
            url:  baseUrl + '/replace',
            type: "POST",
            data: data,
            success: function(data, textStatus, jqXHR) {
                window.location = "/thank-you-replace?pdf=" + encodeURIComponent(data);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                jQuery('#replaceForm .form-submit-error').html(jqXHR.responseJSON );
            }
        });
    }
}
function submitRequest(formID)
{
    var baseUrl = 'https://copay.medthink.com/horizant/card';
    jQuery(formID +' .form-submit-error').html("");
    var data = jQuery(formID).serializeArray();
    console.log(formID);
    console.log(data);
    //strip phone formatting
    var phoneIndex = data.findIndex(x => x.name === 'phone')
    data[phoneIndex].value = data[phoneIndex].value.replace(/\D+/g, "");

    //check for condition checks and set values to N if not selected
    var cond1Index = data.findIndex(x => x.name === 'condition1');
    var cond2Index = data.findIndex(x => x.name === 'condition2');
    var cond4Index = data.findIndex(x => x.name === 'condition4');

    if (!jQuery(formID +' .condition1').prop('checked'))
    {
        data.push({name:'condition1', value: 'N'});
    }
    if (!jQuery(formID +' .condition2').prop('checked'))
    {
        data.push({name:'condition2', value: 'N'});
    }
    if (!jQuery(formID +' .condition4').prop('checked'))
    {
        data.push({name:'condition4', value: 'N'});
    }
    //check and remove addy2
    var addy2 = data.findIndex(x => x.name === 'address2');
    if (data[addy2].value === "")
    {
        data.splice(addy2, 1);
    }

    //fix date format
    var dobIndex = data.findIndex(x => x.name === 'dob');
    var year = data[dobIndex].value.split('-')[0];
    var month = data[dobIndex].value.split('-')[1];
    var day = data[dobIndex].value.split('-')[2];
    data[dobIndex].value = month + '/' + day + '/' + year;
    
    //check validity
    console.log(data)
    if(jQuery(formID)[0].checkValidity())
    {
        //submit
        jQuery.ajax({
            url:  baseUrl + '/request',
            type: "POST",
            data: data,
            success: function(respData, textStatus, jqXHR) {
                //lower all
                var lowerData = data.map(function(a) { 
                    a.name = a.name.toLowerCase();
                    return a;
                });

                //change zip and address

                data[data.findIndex(x => x.name === 'zip')].name = "postalcode";

                data[data.findIndex(x => x.name === 'address1')].name = "street";

                var address2Index = data.findIndex(x => x.name === 'address2');
                if (data[address2Index])
                {
                    data[address2Index].name = "street2";
                }


                console.log('before icontat submit')
              
                lowerData.push({name:'contactListId', value: 77701 });
                lowerData.push({name:'contactListFolderId', value: 23583 });

                console.log(lowerData);
                submitToIContact(lowerData);

               window.location = "/thank-you?pdf=" + encodeURIComponent(respData);
                
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(jqXHR.responseJSON)
                jQuery(formID + ' .form-submit-error').html(jqXHR.responseJSON );
            }
        });

    }
}

function submitToIContact(data) {
    // determine route
    var iContactRoute = 'https://copay.medthink.com/icontact';
    // if (formID === '#activateRequestForm')
    // {
    //     iContactRoute = 'activate';
    // }
    jQuery.ajax({
        url:  iContactRoute,
        type: "POST",
        data: data,
        success: function(data, textStatus, jqXHR) {
            console.log('submitted to icontact');
        },
        error: function(jqXHR, textStatus, errorThrown) {
    
            console.log(jqXHR.responseJSON);
        }
    });
}

function submitActivate()
{
    jQuery('.invalid-card-error').html("");
    var baseUrl = 'https://copay.medthink.com';
    jQuery('.invalid-card-error').html("");
    var data = jQuery('#activateForm').serializeArray();
    console.log(data)
    //check validity
    if(jQuery("#activateForm")[0].checkValidity())
    {
        //submit
        console.log('activate check')
        jQuery.ajax({
            url: baseUrl + '/horizant/card/activate',
            type: "POST",
            data: data,
            success: function(data, textStatus, jqXHR) {
                //jQuery('.requestForm').toggle()
                //jQuery('.activateForm').toggle()
                jQuery('.hidden-card-holder').append('<input id="hidden-card" name="card" value="'+ jQuery('#activate-card').val() +'" type="hidden">');
                // jQuery('html,body').animate({scrollTop: jQuery('#requestForm').offset().top - 100},'slow');
                console.log(data)
            },
            error: function(jqXHR, textStatus, errorThrown) {
                jQuery('.invalid-card-error').html( "Sorry, but that ID number doesn’t seem to be working. Can you try again? Please double check to ensure the numbers are entered correctly. Thank you. If you still experience trouble, please call 1-877-901-0488" );
            }
        });
    }
}

function popSuccess(content)
{
    jQuery('#savingsPopup .inner-modal-content').html(content);
    jQuery('#savingsPopup').foundation('open');
}

// function handleRepForm(){
//     jQuery('#repform').submit(function(e){   
//         e.preventDefault();
//         var data = jQuery(this).serializeArray();
//         console.log(data);
//         // jQuery.ajax({
//         //     url: '/rep',
//         //     type: "POST",
//         //     data: data,
//         //     success: function(data, textStatus, jqXHR) {
//         //         console.log(data);
//         window.location = "/hcp/sign-up/thank-you";
//         //     },
//         //     error: function(jqXHR, textStatus, errorThrown) {
//         //         console.log(jqXHR.responseJSON);
//         //     }
//         // });
//     }); 

// }

// function handleRepForm() {
//     jQuery('#repForm').submit(function(e) {
//         e.preventDefault();
//         let firstName = jQuery('#firstname').val();
//         let lastName = jQuery('#lastname').val();
//         let phone = jQuery('#phone').val();
//         let email = jQuery('#email').val();
//         var data = {
//             firstName : firstName,
//             lastName : lastName,
//             phone : phone,
//             email : email,
//             contactListId : 60921,
//             contactListFolderId : 23534
//         };
//         jQuery.ajax({
//             url: "https://copay.medthink.com/icontact",
//             type: "POST",
//             data: data,
//             success: function(success){
//                 console.log(success);
//                 window.location.assign('/hcp/sign-up/thank-you');
//             }
//         });
//     });
// }

function phoneNumberFormat() {
    console.log('formatting');
	jQuery('.phone')
	.keydown(function (e) {
		var key = e.which || e.charCode || e.keyCode || 0;
		$phone = jQuery(this);

		// Don't let them remove the starting '('
		if ($phone.val().length === 1 && (key === 8 || key === 46)) {
			$phone.val('('); 
			return false;
		} 
		// Reset if they highlight and type over first char.
		else if ($phone.val().charAt(0) !== '(') {
			$phone.val('('+$phone.val()); 
		}

		// Auto-format- do not expose the mask as the user begins to type
		if (key !== 8 && key !== 9) {
			if ($phone.val().length === 4) {
				$phone.val($phone.val() + ')');
			}
			if ($phone.val().length === 5) {
				$phone.val($phone.val() + ' ');
			}           
			if ($phone.val().length === 9) {
				$phone.val($phone.val() + '-');
			}
		}

		// Allow numeric (and tab, backspace, delete) keys only
		return (key == 8 || 
				key == 9 ||
				key == 46 ||
				(key >= 48 && key <= 57) ||
				(key >= 96 && key <= 105)); 
	})

	.bind('focus click', function () {
		$phone = jQuery(this);

		if ($phone.val().length === 0) {
			$phone.val('(');
		}
		else {
			var val = $phone.val();
			$phone.val('').val(val); // Ensure cursor remains at the end
		}
	})

	.blur(function () {
		$phone = jQuery(this);

		if ($phone.val() === '(') {
			$phone.val('');
		}
	});
}
