/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function () {


	legalCheck();
	handleHCPCheck();
	
	submit('#repForm', 60921, 23534, '/hcp/sign-up/thank-you/');
	submit('#signup', 83736, 23583, '/sign-up/thank-you');

	function submit(form, list, folder, ty) {
		console.log('signup set')
		jQuery(form).submit(function(e) {
			e.preventDefault();
	
			let data = jQuery(this).serializeArray();
	
			data.push({name: "contactListId", value: list});
			data.push({name: "contactListFolderId", value: folder});
	
			jQuery.ajax({
				url: "https://copay.medthink.com/icontact",
				type: "POST",
				data: jQuery.param(data),
				success: function(success){
					console.log(success);
					window.location.assign(ty)
				}
			});
		});
	}
		  
	


	function legalCheck(){
		var check = jQuery('#requestCheck');
		check.on('change', function(){
			if(check.prop('checked')) {
				jQuery('#requestSubmit').removeClass('disabled').removeAttr('disabled');
			} else {
				jQuery('#requestSubmit').addClass('disabled').attr('disabled', 'true');
			}
		});
		jQuery(':required').one('blur keydown', function() {
		  console.log('touched', this);
		  jQuery(this).addClass('touched');
		});
	}

	function handleHCPCheck(){
		if (localStorage.getItem('hcp') !== 'true' && window.location.pathname.includes('hcp')) {
		jQuery('#global-hcp-modal').foundation('open');

		jQuery(".hcp-agree").click(function (event) {
			localStorage.setItem('hcp', 'true');
			jQuery('#global-hcp-modal').foundation('close');
		});
		}
	}

	transcript();
	///sticky isi
	var sticky = jQuery('#isi').clone();
	jQuery('body').append("<div class='sticky-isi-cont'><div class='grid-container'><div class='isi grid-x'>" + sticky.prop('outerHTML') + "</div></div></div>");

	var distance = jQuery(window).scrollTop();
	var isiHeight = jQuery('#isi').offset().top;

	jQuery(window).on('scroll', function () {
		var offset = 650;
		if (location.pathname === '/') {
			offset = 450;
		}
		if (jQuery(window).scrollTop() >= isiHeight - offset) {
			//console.log(jQuery(window).scrollTop())
			jQuery(".sticky-isi-cont").animate({
				height: "0"
			}, 500, function () {
				// Animation complete.
			});
		}
	});

	//fix button like anchors
	jQuery('.hz-button').attr('role', 'button');
	jQuery('.cta-fw-button').attr('role', 'button');


	//if href is external, add a class
	jQuery('a').each(function () {

		
		if (this.href.includes('#http')) {
			console.log('ext')
			jQuery(this).addClass('external');
		}
	});

	jQuery('.gb-button').each(function () {
		jQuery(this).attr('role', 'button');
	});



	jQuery(".image-desc-title").click(function (event) {

		var icon = jQuery(this).children(".desc-expanded-icon")
		if (icon.text() == "+") {
			icon.text("-");
		} else {
			icon.text("+");
		}
		jQuery(this).next().slideToggle(0, function () {
			jQuery('.gb-grid-wrapper .cta-box-container').matchHeight();
		});

	});




	jQuery('.cta-heading').matchHeight();
	jQuery('.match-height').matchHeight();
	jQuery('.match-ctas').matchHeight();

	jQuery('.pt-container-header').matchHeight();

	jQuery('p, h1, h2, h3, h4, h5, a, span, li').each(function () {
		var text = jQuery(this).html().replace(/®/g, '<sup>®</sup>');
		var text2 = text.replace(/†/g, '<sup>†</sup>');

		jQuery(this).html(text2);
	});

	jQuery('h2').each(function () {
		var text = jQuery(this).html().replace(/II/g, '<sup>II</sup>');
		jQuery(this).html(text);
	});
	// handle li differently
	jQuery('li').each(function () {
		//var text = jQuery(this).html().replace(/®/g, '<sup>®</sup>');
		//var text2 = text.replace(/†/g, '<sup>†</sup>');
		//jQuery(this).html(text2);
	});


	// jQuery('.reveal').each(function () {
	// 	console.log('reveal');
	// 	var opener = '#' + jQuery(this).attr('data-opener');
	// 	var noHashOpener = jQuery(this).attr('data-opener');
	// 	console.log(opener)
	// 	jQuery('a[href="' + opener + '"]').click(function (event) {
	// 		console.log('div[data-opener="' + opener + '"]')
	// 		jQuery('div[data-opener="' + noHashOpener + '"]').foundation('open');
	// 	});
	// });

	jQuery('a[href="#close"]').click(function (event) {
		jQuery('.reveal').foundation('close');
	});

	jQuery('a[href="#request-card"]').click(function (event) {
		hcpSignupStep1();
		jQuery('#signup-modal').foundation('open');
	});

	jQuery('#patient-request-card').submit(function (event) {
		event.preventDefault();

		var form = jQuery(this);
		var actionUrl = form.attr('action');
		var data = jQuery(formID).serializeArray();
		var phoneIndex = data.findIndex(x => x.name === 'phone')
    data[phoneIndex].value = data[phoneIndex].value.replace(/\D+/g, "");
		jQuery.ajax({
			type: "POST",
			url: actionUrl,
			data: form.serialize(), // serializes the form's elements.
			success: function(data)
			{
			  alert(data); // show response from the php script.
			}
		});
		
	});



	jQuery('.request-card').click(function (event) {
		signupStep1();
		jQuery('#signup-modal').foundation('open');
	});

	jQuery('.explore-hcp-savings').click(function (event) {
		console.log('clicker')
		hcpStep1();
		jQuery('#signup-modal').foundation('open');
	});

	//close
	jQuery('.closeHelloBar').click(function (event) {
		jQuery(".helloBar").slideUp("slow", function () {
			// Animation complete.
		});

	});



	// jQuery('.explore-savings').click(function (event) {
	// 	event.preventDefault();
	// 	console
	// 	step1();
	// 	jQuery('#signup-modal').foundation('open');
	// });

	jQuery('.activate-patient-card').click(function (event) {
		activateModal();
		jQuery('#signup-modal').foundation('open');
	});

	jQuery('.replace-patient-card').click(function (event) {
		replaceModal();
		jQuery('#signup-modal').foundation('open');
	});


	jQuery('.open-explore-savings').click(function (event) {
		event.preventDefault();
		step1();
		jQuery('#signup-modal').foundation('open');
	});


	jQuery('.symptom-check').click(function (event) {
		event.preventDefault();
		symptomStep1();

		jQuery('#audit-modal').foundation('open');
	});


	



	jQuery('.header-bottom-menu li a').each(function () {
		//console.log(window.location.pathname)
		//console.log('d')
		//console.log(jQuery(this).attr('href'))
		if (jQuery(this).attr('href') == window.location.pathname.replace(/\/$/, "")) {
			jQuery(this).addClass('active');
		}
	});

	jQuery('.pt-header-bottom-menu li a').each(function () {
		//console.log(window.location.pathname)
		//console.log('d')
		//console.log(jQuery(this).attr('href'))
		if (jQuery(this).attr('href') == window.location.pathname.replace(/\/$/, "")) {
			jQuery(this).addClass('active');
		}
	});


	jQuery('.gb-grid-wrapper .cta-box-container').matchHeight();

	// Remove empty P tags created by WP inside of Accordion and Orbit
	jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function () {
		if (jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5) {
			jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
			jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});

	jQuery('a[href*="#terms-pop"]').click(function (event) {
		event.preventDefault();


		jQuery(window).scrollTop(jQuery('.terms-display').position().top);


		console.log('click')
		var terms = jQuery('.terms-display').children('a');
		console.log(terms)
		var icon = terms.children(".desc-expanded-icon")
		if (icon.text() == "+") {
			icon.text("-");
		} else {
			icon.text("+");
		}
		terms.next().slideToggle(0, function () {
			jQuery('.gb-grid-wrapper .cta-box-container').matchHeight();
		});
	});

	jQuery('.external').click(function (event) {
		
		event.preventDefault();	
		var url = jQuery(this).attr('href');
		globalOffsiteModal(url.substring(1));	
	});

		initSavings();
		card();
		phoneNumberFormat();
		handleNav();
		//handleRepForm();
		

});



function handleNav() {

	console.log('nav set')
	jQuery('.toggle-nav').on('click', function(){
		jQuery(this).toggleClass('kb-active');
		jQuery(this).parent().find('ul').stop().slideToggle()
		if (jQuery(this).hasClass('kb-active')) {
			jQuery(this).attr('aria-expanded', 'true');
		}
		else
		{
			jQuery(this).attr('aria-expanded', 'false');
		}
	});


	jQuery('.top-level-nav-link').mouseenter(function () {
		jQuery(this).parent().find('.toggle-nav').addClass('kb-active');
		jQuery(this).parent().find('.toggle-nav').attr('aria-expanded', 'true');
		jQuery(this).parent().find('ul').stop().slideToggle()
	});
	// jQuery('.top-lev-nav-item ul').mouseleave(function () {
	// 	console.log('bye ul')
	// 	jQuery(this).parent().find('.toggle-nav').removeClass('kb-active');
	// 	jQuery(this).stop().slideToggle()
	// 	jQuery(this).parent().find('.toggle-nav').attr('aria-expanded', 'false');
	// })

	jQuery('.top-lev-nav-item').mouseleave(function () {
		console.log('bye all')
		jQuery(this).parent().find('.toggle-nav').removeClass('kb-active');
		jQuery(this).find('ul').stop().slideToggle()
		jQuery(this).parent().find('.toggle-nav').attr('aria-expanded', 'false');
	})

}


var $focusTriggerGlobal;

function modalFocusManager() {
	jQuery(".modalTrigger").click(function () {
		//set global to return focus
		$focusTriggerGlobal = jQuery(this);
		//grab class to find modal
		var modalClass = jQuery(this).attr('data-modal');
		var $closeBtn = jQuery(modalClass).find('.modalClose').first();
		// add focus classes
		jQuery(this).addClass("return-focus-here");
		$closeBtn.addClass("set-focus-here");
		$closeBtn.attr('tabindex', '-1');
		var elementToFocus = document.querySelector('.set-focus-here');
		var initialFocus = document.querySelector('.return-focus-here');
		//fix focus on turn on keytrap
		focusFixer(initialFocus, elementToFocus);
		modalKeyTrap(modalClass);
	});

	//when modal is closed
	jQuery(".modalClose").click(function () {
		$focusTriggerGlobal.focus();
	});
}

function focusFixer(initialFocusElement, newFocusElement) {
	setTimeout(function () {
		newFocusElement.focus();
		if (!document.activeElement.classList.contains("set-focus-here")) {
			//recurse until correct focus is detected
			focusFixer(initialFocusElement, newFocusElement);
		}
	}, 10);
}

function modalKeyTrap(selector) {
	
	
	var elements = findFocusableElements(selector);
	var fist = jQuery(elements[0]).addClass('first-focusable-element');
	var last = jQuery(elements[elements.length - 1]).addClass('last-focusable-element');
	var dialog = document.querySelector('[role="dialog"]');
	var firstFocusableElement = dialog.querySelector('.first-focusable-element');
	var lastFocusableElement = dialog.querySelector('.last-focusable-element');

	//bind keytrap
	dialog.addEventListener('keydown', function (e) {
		if (e.target == firstFocusableElement && e.key == 'Tab' && e.shiftKey) {
			e.preventDefault();
			lastFocusableElement.focus();
		} else if (e.target == lastFocusableElement && e.key == 'Tab' && !e.shiftKey) {
			e.preventDefault();
			firstFocusableElement.focus();
		}
	});
}

function findFocusableElements(selector) {
	var $elements = jQuery(selector).find('button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])');
	return $elements;

}

function transcript() {
	console.log('scrip')
	var trigger = jQuery('.transTrigger');
	var target = jQuery('.transTarget');
	trigger.click(function (e) {
		if (jQuery(this).attr('aria-expanded') == 'true') {
			console.log('true')
			jQuery(this).attr('aria-expanded', "false");
		} else {
			console.log('false')
			jQuery(this).attr('aria-expanded', "true");
		}
	});
}


function symptomStep1() {
	var symptomStep1Template = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">Your RLS Symptom Checker</h2>
	<strong><p class="modal-subheading">1)  Do you experience any of the following?</p></strong>
	<fieldset>          
	<div><input type="checkbox" class="sensory-symptom"/><span class="symptom-text"> Unable to get comfortable</span> </div>    
	<div><input type="checkbox" class="sensory-symptom"/><span class="symptom-text"> Pain</span> </div>      
	<div><input type="checkbox" class="sensory-symptom"/><span class="symptom-text"> Uncomfortable feelings</span> </div>    
	<div><input type="checkbox" class="sensory-symptom"/><span class="symptom-text"> Urge to move</span> </div>          
	</fieldset>  
	<br>
	<strong><p class="modal-subheading">2)  Do you experience any of the following?</p></strong>
	<fieldset>          
	<div><input type="checkbox" class="sleep-symptom"/><span class="symptom-text"> Unable to fall asleep</span> </div>    
	<div><input type="checkbox" class="sleep-symptom"/><span class="symptom-text"> Unable to stay asleep</span> </div>      
	<div><input type="checkbox" class="sleep-symptom"/><span class="symptom-text"> Disturbed sleep</span> </div>    
	<div><input type="checkbox" class="sleep-symptom"/><span class="symptom-text"> Poor sleep quality</span> </div> 
	<br>      
	<a role="button" class="pt-hz-button next-button" href="#next">Submit</a>    
	</fieldset>
</div>`;



	jQuery('#audit-modal').html(symptomStep1Template);

	jQuery(".next-button").click(function (event) {
		sensSymsAr = [];
		sleepSymsAr = [];
		jQuery(".sensory-symptom").each(function () {	
			if(jQuery(this).is(":checked")) {
				sensSymsAr.push(jQuery(this).next().text());
			}
		});

		jQuery(".sleep-symptom").each(function () {	
			if(jQuery(this).is(":checked")) {
				sleepSymsAr.push(jQuery(this).next().text());
			}
		});

		symptomStep2(sensSymsAr, sleepSymsAr);

	});

}


function symptomStep2(sensSymsAr,sleepSymsAr) {

	var sensSymCount = sensSymsAr.length;
	var sleepSymCount = sleepSymsAr.length;
	var symHtml = "";
	var sleepHtml = "";
	var sensPlural = "s";
	var sleepPlural = "s";

	if(sensSymCount == 1) {	
		sensPlural = "";
	}

	if(sleepSymCount == 1) {	
		sleepPlural = "";
	}


	// loop through the array of symptoms and create a list item for each
	jQuery.each(sensSymsAr, function (index, value) {
		symHtml = symHtml + "<li>" + value + "</li>";
	});

	jQuery.each(sleepSymsAr, function (index, value) {
		sleepHtml = sleepHtml + "<li>" + value + "</li>";
	});



	var symptomStep1Template = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">Your RLS Symptom Checker</h2>
	
	<div class="sensory-symptoms symp-res grid-x">
	<div class="cell shrink"><img width="100px" alt="person standing with lightning bolt icon" src="/wp-content/uploads/2023/01/leg-lightning.webp"></div>
	<div class="cell auto">
		<p>Based on your selection, you experience <strong>${sensSymCount} sensory symptom${sensPlural}</strong> of RLS.</p>
		<ul>${symHtml}</ul>
	</div>
	</div>
	
	<div class="sleep-symptoms symp-res grid-x">
	<div class="cell shrink"><img alt="person lying in bed icon" width="100px" src="/wp-content/uploads/2023/01/bed-1.webp"></div>
	<div class="cell auto">
		<p>Based on your selection, you experience <strong>${sleepSymCount} sleep symptom${sleepPlural}</strong> of RLS.</p>
		<ul>${sleepHtml}</ul>
	</div>
	</div>
	<p class="modal-subheading">Be sure to keep track of your symptoms and communicate them with your healthcare provider to see if you might be a candidate for treatment. </p>
	<a class="pt-hz-button next-button" href="https://horizant.com/pdf/RLS-doctor-discussion-guide.pdf">Doctor Discussion Guide</a>   
	</div>`;



	jQuery('#audit-modal').html(symptomStep1Template);

	jQuery(".next-button").click(function (event) {

		if (jQuery('input[name="description"]:checked').val() === 'rx') {
			step2();
		} else {
			step3();
		}

	});

}




function globalOffsiteModal(url) {
	var offsiteTemplate = `<div>
								<button class="close-button" data-close="" aria-label="Close" type="button">
									<span aria-hidden="true">×</span>
								</button>
								<h2 class="modal-heading">You are leaving Horizant.com</h2>
								<p>This link is taking you to another site.</p>
								<a role="button" class="pt-hz-button arbor-save-button" href="#" data-close="" aria-label="Close modal" type="button">Go back</a>
								<a role="button" class="pt-hz-button arbor-save-button" target="_blank" href="${url}" type="button">Continue</a>
							</div>`;

		jQuery('#global-exit-modal').html(offsiteTemplate);
		jQuery('#global-exit-modal').foundation('open');
}


function step1() {
	var savingsTemplate1 = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">What best describes you?</h2>
	<fieldset>          
	<input class="modal-radio" type="radio" name="description" value="rx" checked>I have prescription insurance through my employer or through a private insurance plan<br/>    
	<input class="modal-radio" type="radio" name="description" value="gov">I have Medicare or other government-provided insurance<br/>      
	<input class="modal-radio" type="radio" name="description" value="none">I do not have prescription insurance<br/>      
	<br>      
	<a role="button" class="pt-hz-button next-button" href="#next">Next</a>    
	</fieldset>  
</div>`;



	jQuery('#signup-modal').html(savingsTemplate1);

	jQuery(".next-button").click(function (event) {

		if (jQuery('input[name="description"]:checked').val() === 'rx') {
			step2();
		} else {
			step3();
		}

	});

}

function step2() {


	var savingsTemplate2 = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">Azurity Solutions E-Z Rx™</h2>
	<p>Based on the provided information, you may be eligible to pay $0 for up to 60 tablets of Horizant<sup>®</sup> per month through our premier independent pharmacy network.</p>    
	<a role="button" class="pt-hz-button ez-rx-exit" href="#">Save now</a>
	<h2 class="modal-heading" style="margin-top:30px;">Copay Savings Card</h2>
	<p>You may also use our Copay Savings Card to pay as little as $0 for 60 tablets of Horizant<sup>®</sup> per month. You can use these instant savings at the pharmacy of your choice.</p>
	<a role="button" class="pt-hz-button request-card-button" href="#savings-signup">Request card</a>
</div>`;

	jQuery('#signup-modal').html(savingsTemplate2);

	jQuery(".request-card-button").click(function (event) {
		signupStep1();
	});

	jQuery(".ez-rx-exit").click(function (event) {
		rxExit();
	});


}

function step3() {



	var savingsTemplate3 = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">Arbor Patient Direct*</h2>
	<p>Based on the provided information, you may be eligible for our cash-only Arbor Patient Direct* home-delivery program for as little as $55 plus shipping for 30 tablets of Horizant<sup>®</sup>.</p>    
	<p><small>*Arbor Pharmaceuticals, LLC is a subsidiary of Azurity Pharmaceuticals, Inc.</small></p>
	<a type="button" role="button" class="pt-hz-button arbor-save-button" href="#">Save now</a>
</div>`;

	jQuery('#signup-modal').html(savingsTemplate3);

	jQuery(".arbor-save-button").click(function (event) {
		offsiteModal('https://arborpatientdirect.com/');
	});

}


function offsiteModal() {

	var offsiteTemplate = `<div>
								<button class="close-button" data-close="" aria-label="Close" type="button">
									<span aria-hidden="true">×</span>
								</button>
								<h2 class="modal-heading">You are leaving Horizant.com</h2>
								<p>This link is taking you to another site.</p>
								<a role="button" class="pt-hz-button arbor-save-button" href="#" data-close="" aria-label="Close modal" type="button">Go back</a>
								<a role="button" class="pt-hz-button arbor-save-button" target="_blank" href="https://arborpatientdirect.com/" type="button">Continue</a>
							</div>`;

		jQuery('#signup-modal').html(offsiteTemplate);
}




function signupStep1() {


	var copayTemplate1 = `<div><button class="close-button" data-close="" aria-label="Close modal" type="button">
	<span aria-hidden="true">×</span>
</button>
<h2 class="modal-heading">Welcome to the Horizant<sup>®</sup> Copay Savings Card program</h2>
		<p>
		<strong>Patient: Eligible patients may pay as little as $0 for up to 60 tablets of Horizant per month. For questions regarding your eligibility or benefits or if you wish to discontinue your particpation, call 1-855-700-2290 (8 AM - 8 PM, ET, Monday - Friday).
		</strong>
		</p>
		<p style="margin-bottom:0px;">
		<strong>Pharmacist:</strong> When you use this card, you are certifying that you have not submitted and will not submit a claim for reimbursement under any federal, state, or other governmental programs for this prescription. By redeeming this coupon, you agree that you understand and will abide by the terms and conditions of this offer, posted at <a href="https://www.mckesson.com/mprstnc">www.mckesson.com/mprstnc</a>.
		</p>
		<a role="button" class="pt-hz-button request-copay" href="#copay-request" style="">Request Copay Savings Card!</a>

	    <p>*Must meet eligibility requirements. Azurity Pharmaceuticals, Inc. reserves the right to rescind, revoke, or amend this offer without notice. See Terms and Conditions below.<br>All personal information will be kept confidential and will not be shared with any parties other than Azurity Pharmaceuticals, Inc. and its designated partners.</p>

	<h3>Terms and Conditions</h3>
	<ul>
<li>Submit transaction to McKesson Corporation using BIN #610524.</li>
<li>Patient not eligible if prescriptions are paid in part or full by any state or federally funded programs, including but not limited to, Medicare or Medicaid, Medigap, VA, DOD, or TriCare. This program is not valid where prohibited by law; you will otherwise comply with the terms above. Not intended for distribution to healthcare providers in Vermont.</li>
<li>If primary coverage exists, input card information as secondary coverage and transmit using the coordination of benefits (COB) segment of the National Council for Prescription Drug Programs (NCPDP) transaction. Applicable discounts will be displayed in the transaction response.</li>
<li>Acceptance of this card and your submission of claims for the Horizant<sup>®</sup> Copay Savings Card program are subject to the Horizant<sup>®</sup> Copay Savings Card program Terms and Conditions posted at <a href="https://www.mckesson.com/mprstnc">www.mckesson.com/mprstnc</a>.
	<ul><li>LoyaltyScript<sup>®</sup> is not an insurance card.</li></ul>
</li>
<li>For questions regarding setup, claim transmission, patient eligibility, or other issues, call 1-855-700-2990 (8 AM–8 PM ET, Monday–Friday).</li>
</div>`;

	jQuery('#signup-modal').html(copayTemplate1);

	jQuery(".request-copay").click(function (event) {

		signupStep2();

	});
}

function signupStep2() {

	var copayTemplate2 = `<button class="close-button" data-close="" aria-label="Close modal" type="button">
	<span aria-hidden="true">×</span>
</button>
<h2 class="modal-heading">Request a card</h2>
		<form class="requestForm" id="requestForm" action="javascript:0" style="display: block;">
			<div class="hidden-card-holder"></div>
			<div class="grid-x grid-padding-x align-middle">
				<div class="cell small-12 medium-7">
					<p>To activate the card on behalf of a minor, you must be the parent, legal guardian, or representative and
						be at least 18 years of age*</p>
				</div>
				<div class="cell small-12 medium-auto">
					<div class="grid-x grid-margin-x">
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="minor" required=""
								class="touched"><label style="display:inline-block;margin-right:10px;">I Agree</label></div>
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="minor"><label
								style="display:inline-block;margin-right:10px;">I Disagree</label></div>
					</div>
				</div>
				<div class="cell small-12 medium-7"><label for="">Patient first name*</label></div>
				<div class="cell small-12 medium-auto"><input type="text" name="first" required=""
						placeholder="Patient first name" class="touched"></div>
				<div class="cell small-12 medium-7"><label for="">Patient last name* </label></div>
				<div class="cell small-12 medium-auto"><input type="text" name="last" required=""
						placeholder="Patient last name" class="touched"></div>
				<div class="cell small-12 medium-7"><label for="">Mailing address 1*</label></div>
				<div class="cell small-12 medium-auto"><input type="text" name="address1" required=""
						placeholder="Mailing address 1" class="touched"></div>
				<div class="cell small-12 medium-7"><label for="">Mailing address 2</label></div>
				<div class="cell small-12 medium-auto"><input type="text" name="address2" placeholder="Mailing address 2"></div>
				<div class="cell small-12 medium-7"><label for="">City*</label></div>
				<div class="cell small-12 medium-auto"><input type="text" name="city" required="" placeholder="City"
						class="touched"></div>
				<div class="cell small-12 medium-7"><label for="">State/Territory*</label></div>
				<div class="cell small-12 medium-auto"><select name="state" required="" class="touched">
						<option selected="" disabled="" hidden="">- Please Select -</option>
						<option value="AL">Alabama</option>
						<option value="AK">Alaska</option>
						<option value="AZ">Arizona</option>
						<option value="AR">Arkansas</option>
						<option value="CA">California</option>
						<option value="CO">Colorado</option>
						<option value="CT">Connecticut</option>
						<option value="DE">Delaware</option>
						<option value="DC">District Of Columbia</option>
						<option value="FL">Florida</option>
						<option value="GA">Georgia</option>
						<option value="HI">Hawaii</option>
						<option value="ID">Idaho</option>
						<option value="IL">Illinois</option>
						<option value="IN">Indiana</option>
						<option value="IA">Iowa</option>
						<option value="KS">Kansas</option>
						<option value="KY">Kentucky</option>
						<option value="LA">Louisiana</option>
						<option value="ME">Maine</option>
						<option value="MD">Maryland</option>
						<option value="MA">Massachusetts</option>
						<option value="MI">Michigan</option>
						<option value="MN">Minnesota</option>
						<option value="MS">Mississippi</option>
						<option value="MO">Missouri</option>
						<option value="MT">Montana</option>
						<option value="NE">Nebraska</option>
						<option value="NV">Nevada</option>
						<option value="NH">New Hampshire</option>
						<option value="NJ">New Jersey</option>
						<option value="NM">New Mexico</option>
						<option value="NY">New York</option>
						<option value="NC">North Carolina</option>
						<option value="ND">North Dakota</option>
						<option value="OH">Ohio</option>
						<option value="OK">Oklahoma</option>
						<option value="OR">Oregon</option>
						<option value="PA">Pennsylvania</option>
						<option value="RI">Rhode Island</option>
						<option value="SC">South Carolina</option>
						<option value="SD">South Dakota</option>
						<option value="TN">Tennessee</option>
						<option value="TX">Texas</option>
						<option value="UT">Utah</option>
						<option value="VT">Vermont</option>
						<option value="VA">Virginia</option>
						<option value="WA">Washington</option>
						<option value="WV">West Virginia</option>
						<option value="WI">Wisconsin</option>
						<option value="WY">Wyoming</option>
					</select></div>
				<div class="cell small-12 medium-7"><label for="">Zip*</label></div>
				<div class="cell small-12 medium-auto"><input type="text" name="zip" pattern="[0-9]+" maxlength="5" required=""
						placeholder="Zip" class="touched"></div>
				<div class="cell small-12 medium-7"><label for="">Phone number*</label></div>
				<div class="cell small-12 medium-auto"><input class="phone touched" type="text" name="phone" required=""
						maxlength="14" placeholder="Phone number"></div>
				<div class="cell small-12 medium-7"><label for="">Email address*</label></div>
				<div class="cell small-12 medium-auto"><input type="email" name="email" required="" placeholder="Email address"
						class="touched">
					<div data-lastpass-icon-root="true"
						style="position: relative !important; height: 0px !important; width: 0px !important; float: left !important;">
					</div>
				</div>
				<div class="cell small-12 medium-7"><label for="">Date of birth* </label></div>
				<div class="cell small-12 medium-auto"><input id="dob" type="date" name="dob" pattern="d{1,2}/d{1,2}/d{4}"
						required="" placeholder="MM/DD/YYYY" class="touched"></div>
				<div class="cell small-12 medium-7" style="padding-top:30px;">
					<p>Are you enrolled in any government, state, or federally funded prescription benefit program? This
						includes Medicare, Medicaid, Medigap, VA, DOD and Tricare?</p>
				</div>
				<div class="cell small-12 medium-auto">
					<div class="grid-x grid-margin-x">
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="govPaid" value="Y"
								required="" class="touched"><label style="display:inline-block;margin-right:10px;">Yes</label>
						</div>
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="govPaid" value="N"><label
								style="display:inline-block;margin-right:10px;">No</label></div>
					</div>
				</div>
				<div class="cell small-12 medium-7" style="padding-top:30px;">
					<p>Your card is not valid for prescriptions purchased under Medicaid, Medicare or any other state or federal
						employee-benefit programs. Should you begin receiving prescription benefits from such federal, state, or
						government funded program at any time, you will no longer be eligible to participate in this program. We
						may contact you by phone or mail periodically in order to verify that your eligibility for the program
						has not changed. Do you acknowledge your agreement with this statement?</p>
				</div>
				<div class="cell small-12 medium-auto">
					<div class="grid-x grid-margin-x">
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="medAck" value="Y"
								required="" class="touched"><label style="display:inline-block;margin-right:10px;">Yes</label>
						</div>
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="medAck" value="N"><label
								style="display:inline-block;margin-right:10px;">No</label></div>
					</div>
				</div>
				<div class="cell small-12 medium-7" style="padding-top:30px;">
					<p>You understand that the transactions processed using the card will be shared with Arbor Pharmaceuticals,
						the sponsor of the card. The information disclosed will include the date a transaction was processed
						using the card, the product dispensed by my pharmacist, and the amount that I will be reimbursed by
						Arbor Pharmaceuticals under the Horizant<sup>®</sup> Copay Savings Program. This information will be
						available to Arbor Pharmaceuticals and third parties working on behalf of Arbor Pharmaceuticals and will
						not be shared with anyone else.</p>
				</div>
				<div class="cell small-12 medium-auto">
					<div class="grid-x grid-margin-x">
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="permOptIn" value="Y"
								required="" class="touched"><label style="display:inline-block;margin-right:10px;">Yes</label>
						</div>
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="permOptIn"
								value="N"><label style="display:inline-block;margin-right:10px;">No</label></div>
					</div>
				</div>
				<div class="cell small-12 medium-7" style="padding-top:30px;">
					<p>You understand that the personal information you provide and information pertaining to the use of your
						coupon at the pharmacy will be shared with Arbor Pharmaceuticals and its third-party partners. You also
						authorize Arbor Pharmaceuticals and its partners to communicate with you in order to provide program
						benefits such as savings offers, information about your prescription, including refill reminders and new
						prescription requests, as well as program updates and alerts sent directly to your device. If you are a
						resident of California you may opt out of communications of the program entirely at any time by
						contacting CCPAPrivacy@ArborPharma.com or call the following toll-free telephone number 1-844-916-1298.
						You may also exercise your rights allowed under the CCPA by contacting those numbers. If you are not a
						resident of California, you may opt out of the program entirely at any time by contacting
						1-855-700-2990.</p>
				</div>
				<div class="cell small-12 medium-auto">
					<div class="grid-x grid-margin-x">
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="marketingOptIn" value="Y"
								required="" class="touched"><label style="display:inline-block;margin-right:10px;">Yes</label>
						</div>
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="marketingOptIn"
								value="N"><label style="display:inline-block;margin-right:10px;">No</label></div>
					</div>
				</div>
				<div class="cell small-12 medium-7" style="padding-top:30px;">
					<p>You understand and agree that you have not or will not submit a claim for reimbursement under any
						federal, state, or other government program for the covered drug.*</p>
				</div>
				<div class="cell small-12 medium-auto">
					<div class="grid-x grid-margin-x">
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="agree" value="1"
								required="" class="touched"><label style="display:inline-block;margin-right:10px;">I
								Agree</label></div>
						<div class="cell auto"><input type="radio" style="display:inline-block;" name="agree" value="2"><label
								style="display:inline-block;margin-right:10px;">I Disagree</label></div>
					</div>
				</div>
				<div class="cell small-12 medium-7" style="padding-top:30px; min-height:250px;">
					<p>Which condition are you diagnosed with? Check all that apply.</p>
				</div>
				<div class="cell small-12 medium-auto">
					<div class="grid-x grid-margin-x">
						<div class="cell small-12"><input class="condition1" type="checkbox"
								style="display:inline-block;margin-top:10px;float:left;" name="condition1" value="Y"><label
								style="display:inline-block;margin-right:10px;float:left;width:90%;">Moderate-to-severe primary
								Restless Legs Syndrome (RLS)</label></div>
						<div class="cell small-12"><input class="condition2" type="checkbox"
								style="display:inline-block;margin-top:10px;float:left;" name="condition2" value="Y"><label
								style="display:inline-block;margin-right:10px;float:left;width:90%;">Pain from damaged nerves
								(postherpetic pain) that follows healing of shingles (a painful rash that comes after a herpes
								zoster infection)</label></div>
						<div class="cell small-12"><input class="condition4" type="checkbox" style="display:inline-block;"
								name="condition4" value="Y"><label style="display:inline-block;margin-right:10px;">Prefer not to
								respond</label></div>
					</div>
				</div>
				<div class="cell small-12 medium-7" style="padding-top:30px;">
					<p>Preferred method of contact* </p>
				</div>
				<div class="cell small-12 medium-auto"><select name="contactMethod" required="" class="touched">
						<option value="E">Email</option>
						<option value="M">Home Address</option>
					</select></div>
			</div>
			<div class="grid-x">
				<div class="cell auto" style="margin-top:20px;"><button
						class="btn teal submitRequestForm pt-hz-button" value="Submit" type="submit"
						>Submit</button></div>
			</div>
			<div class="grid-x">
				<div class="cell auto">
					<div class="form-submit-error"></div>
				</div>
			</div>
		</form>
</div>`;

	jQuery('#signup-modal').html(copayTemplate2);

	jQuery( ".submitRequestForm" ).click(function( event ) {
        console.log('init sub req')
      submitRequest('#requestForm');
    });

	// jQuery(".submit-copay").click(function (event) {

	// 	signupStep3();

	// });


}


function signupStep3() {



	var thanksTempate = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">Welcome to the savings program!</h2>
	<p>Your savings card should download in a few seconds. If not, <a href="#">click here</a> to download your savings card.</p>
</div>`;

	window.location.href = '/thank-you';

}



//hcp signup

function hcpStep1() {
	var savingsTemplate1 = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">What best describes your patient?</h2>
	<fieldset>          
	<input class="modal-radio" type="radio" name="description" value="rx" checked>My patient has prescription insurance through their employer or through a private insurance plan<br/>    
	<input class="modal-radio" type="radio" name="description" value="gov">My patient has Medicare or other government-provided insurance<br/>      
	<input class="modal-radio" type="radio" name="description" value="none">My patient does not have prescription insurance<br/>      
	<br>      
	<a class="hz-button next-button" href="#next">Next</a>    
	</fieldset>  
</div>`;



	jQuery('#signup-modal').html(savingsTemplate1);

	jQuery(".next-button").click(function (event) {

		if (jQuery('input[name="description"]:checked').val() === 'rx') {
			hcpStep2();
		} else {
			hcpStep3();
		}

	});

}

function hcpStep2() {



	var savingsTemplate2 = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">Azurity Solutions E-Z Rx™</h2>
	<p>Based on the provided information, your patient may be eligible to pay $0 for up to 60 tablets of Horizant<sup>®</sup> per month through our premier independent pharmacy network.</p>    
	<a class="hz-button az-sol-link" role="button" href="#">Save now</a>
	<h2 class="modal-heading" style="margin-top:30px;">Copay Savings Card</h2>
	<p>Your patients may also use our Copay Savings Card to pay as little as $0 for 60 tablets of Horizant<sup>®</sup> per month. Patients can use these instant savings at the pharmacy of their choice.</p>
	<a class="hz-button request-card-button" href="#savings-signup">See Copay Savings</a>
</div>`;

	jQuery('#signup-modal').html(savingsTemplate2);

	jQuery(".request-card-button").click(function (event) {
		hcpSignupStep1();
	});

	jQuery(".az-sol-link").click(function (event) {
		azSolLink();
	});


}



function azSolLink() {

var offsiteTemplate = `<div>
<button class="close-button" data-close="" aria-label="Close" type="button">
	<span aria-hidden="true">×</span>
</button>
<h2 class="modal-heading">You are leaving Horizant.com</h2>
<p>This link is taking you to another site.</p>
<a role="button" class="pt-hz-button arbor-save-button" href="#" data-close="" aria-label="Close modal" type="button">Go back</a>
<a role="button" class="pt-hz-button arbor-save-button" target="_blank" href="https://ezrxlocator.com/" type="button">Continue</a>
</div>`;

jQuery('#signup-modal').html(offsiteTemplate);

}

function rxExit() {

	var offsiteTemplate = `<div>
	<button class="close-button" data-close="" aria-label="Close" type="button">
		<span aria-hidden="true">×</span>
	</button>
	<h2 class="modal-heading">You are leaving Horizant.com</h2>
	<p>This link is taking you to another site.</p>
	<a role="button" class="pt-hz-button arbor-save-button" href="#" data-close="" aria-label="Close modal" type="button">Go back</a>
	<a role="button" class="pt-hz-button arbor-save-button" target="_blank" href="https://ezrxlocator.com/" type="button">Continue</a>
	</div>`;
	
	jQuery('#signup-modal').html(offsiteTemplate);
	
	}



function hcpStep3() {



	var savingsTemplate3 = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">Arbor Patient Direct*</h2>
	<p>Based on the provided information, your patient may be eligible for our cash-only Arbor Patient Direct* home-delivery program for as little as $55 plus shipping for 30 tablets of Horizant<sup>®</sup>.</p>    
	<p><small>*Arbor Pharmaceuticals, LLC is a subsidiary of Azurity Pharmaceuticals, Inc.</small></p>
	<a class="hz-button" href="https://arborpatientdirect.com/">Save now</a>
</div>`;

	jQuery('#signup-modal').html(savingsTemplate3);

}


function hcpSignupStep1() {

	var copayTemplate1 = `<button class="close-button" data-close="" aria-label="Close modal" type="button">
	<span aria-hidden="true">×</span>
</button>
<h2 class="modal-heading">Welcome to the Horizant<sup>®</sup> Copay Savings Card program</h2>
		<p>
		<strong>Patient: Eligible patients may receive their first fill of Horizant<sup>®</sup> 600 mg or 300 mg for $0. For remaining fills, insured patients may pay as little as $25, and cash patients may pay as little as $75. For questions regarding your eligibility or benefits or if you wish to discontinue your participation, call 1-855-700-2990 (8 AM–8 PM ET, Monday–Friday).
		</strong>
		</p>
		<p>
		<strong>Pharmacist:</strong> When you use this card, you are certifying that you have not submitted and will not submit a claim for reimbursement under any federal, state, or other governmental programs for this prescription. By redeeming this coupon, you agree that you understand and will abide by the terms and conditions of this offer, posted at <a href="https://www.mckesson.com/mprstnc">www.mckesson.com/mprstnc</a>.
		</p>
		<a class="hz-button request-copay" href="#copay-request" style="margin-bottom:10px;">Request Copay Savings Card</a>
	    <p><small>*Must meet eligibility requirements. Azurity Pharmaceuticals, Inc. reserves the right to rescind, revoke, or amend this offer without notice. See Terms and Conditions below.<br>All personal information will be kept confidential and will not be shared with any parties other than Azurity Pharmaceuticals, Inc. and its designated partners.</small></p>

	<h3>Terms and Conditions</h3>
	<ul>
<li>Submit transaction to McKesson Corporation using BIN #610524.</li>
<li>Patient not eligible if prescriptions are paid in part or full by any state or federally funded programs, including but not limited to, Medicare or Medicaid, Medigap, VA, DOD, or TriCare. This program is not valid where prohibited by law; you will otherwise comply with the terms above. Not intended for distribution to healthcare providers in Vermont.</li>
<li>If primary coverage exists, input card information as secondary coverage and transmit using the coordination of benefits (COB) segment of the National Council for Prescription Drug Programs (NCPDP) transaction. Applicable discounts will be displayed in the transaction response.</li>
<li>Acceptance of this card and your submission of claims for the Horizant<sup>®</sup> Copay Savings Card program are subject to the Horizant® Copay Savings Card program Terms and Conditions posted at <a href="https://www.mckesson.com/mprstnc">www.mckesson.com/mprstnc</a>.
	<ul><li>LoyaltyScript<sup>®</sup> is not an insurance card.</li></ul>
</li>
<li>For questions regarding setup, claim transmission, patient eligibility, or other issues, call 1-855-700-2990 (8 AM–8 PM ET, Monday–Friday).</li>
</div>`;

	jQuery('#signup-modal').html(copayTemplate1);

	jQuery(".request-copay").click(function (event) {

		hcpSignupStep2();

	});
}

function hcpSignupStep2() {

	var copayTemplate2 = `<button class="close-button" data-close="" aria-label="Close modal" type="button">
		<span aria-hidden="true">×</span>
	</button>
	<h2 class="modal-heading">Request a card</h2>
	<form class="requestForm" id="requestForm" action="javascript:0" style="display: block;">
	<div class="hidden-card-holder"></div>
	<div class="grid-x grid-padding-x align-middle">
		<div class="cell small-12 medium-7">
			<p>To activate the card on behalf of a minor, you must be the parent, legal guardian, or representative and
				be at least 18 years of age*</p>
		</div>
		<div class="cell small-12 medium-auto">
			<div class="grid-x grid-margin-x">
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="minor" required=""
						class="touched"><label style="display:inline-block;margin-right:10px;">I Agree</label></div>
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="minor"><label
						style="display:inline-block;margin-right:10px;">I Disagree</label></div>
			</div>
		</div>
		<div class="cell small-12 medium-7"><label for="">Patient first name*</label></div>
		<div class="cell small-12 medium-auto"><input type="text" name="first" required=""
				placeholder="Patient first name" class="touched"></div>
		<div class="cell small-12 medium-7"><label for="">Patient last name* </label></div>
		<div class="cell small-12 medium-auto"><input type="text" name="last" required=""
				placeholder="Patient last name" class="touched"></div>
		<div class="cell small-12 medium-7"><label for="">Mailing address 1*</label></div>
		<div class="cell small-12 medium-auto"><input type="text" name="address1" required=""
				placeholder="Mailing address 1" class="touched"></div>
		<div class="cell small-12 medium-7"><label for="">Mailing address 2</label></div>
		<div class="cell small-12 medium-auto"><input type="text" name="address2" placeholder="Mailing address 2"></div>
		<div class="cell small-12 medium-7"><label for="">City*</label></div>
		<div class="cell small-12 medium-auto"><input type="text" name="city" required="" placeholder="City"
				class="touched"></div>
		<div class="cell small-12 medium-7"><label for="">State/Territory*</label></div>
		<div class="cell small-12 medium-auto"><select name="state" required="" class="touched">
				<option selected="" disabled="" hidden="">- Please Select -</option>
				<option value="AL">Alabama</option>
				<option value="AK">Alaska</option>
				<option value="AZ">Arizona</option>
				<option value="AR">Arkansas</option>
				<option value="CA">California</option>
				<option value="CO">Colorado</option>
				<option value="CT">Connecticut</option>
				<option value="DE">Delaware</option>
				<option value="DC">District Of Columbia</option>
				<option value="FL">Florida</option>
				<option value="GA">Georgia</option>
				<option value="HI">Hawaii</option>
				<option value="ID">Idaho</option>
				<option value="IL">Illinois</option>
				<option value="IN">Indiana</option>
				<option value="IA">Iowa</option>
				<option value="KS">Kansas</option>
				<option value="KY">Kentucky</option>
				<option value="LA">Louisiana</option>
				<option value="ME">Maine</option>
				<option value="MD">Maryland</option>
				<option value="MA">Massachusetts</option>
				<option value="MI">Michigan</option>
				<option value="MN">Minnesota</option>
				<option value="MS">Mississippi</option>
				<option value="MO">Missouri</option>
				<option value="MT">Montana</option>
				<option value="NE">Nebraska</option>
				<option value="NV">Nevada</option>
				<option value="NH">New Hampshire</option>
				<option value="NJ">New Jersey</option>
				<option value="NM">New Mexico</option>
				<option value="NY">New York</option>
				<option value="NC">North Carolina</option>
				<option value="ND">North Dakota</option>
				<option value="OH">Ohio</option>
				<option value="OK">Oklahoma</option>
				<option value="OR">Oregon</option>
				<option value="PA">Pennsylvania</option>
				<option value="RI">Rhode Island</option>
				<option value="SC">South Carolina</option>
				<option value="SD">South Dakota</option>
				<option value="TN">Tennessee</option>
				<option value="TX">Texas</option>
				<option value="UT">Utah</option>
				<option value="VT">Vermont</option>
				<option value="VA">Virginia</option>
				<option value="WA">Washington</option>
				<option value="WV">West Virginia</option>
				<option value="WI">Wisconsin</option>
				<option value="WY">Wyoming</option>
			</select></div>
		<div class="cell small-12 medium-7"><label for="">Zip*</label></div>
		<div class="cell small-12 medium-auto"><input type="text" name="zip" pattern="[0-9]+" maxlength="5" required=""
				placeholder="Zip" class="touched"></div>
		<div class="cell small-12 medium-7"><label for="">Phone number*</label></div>
		<div class="cell small-12 medium-auto"><input class="phone touched" type="text" name="phone" required=""
				maxlength="14" placeholder="Phone number"></div>
		<div class="cell small-12 medium-7"><label for="">Email address*</label></div>
		<div class="cell small-12 medium-auto"><input type="email" name="email" required="" placeholder="Email address"
				class="touched">
			<div data-lastpass-icon-root="true"
				style="position: relative !important; height: 0px !important; width: 0px !important; float: left !important;">
			</div>
		</div>
		<div class="cell small-12 medium-7"><label for="">Date of birth* </label></div>
		<div class="cell small-12 medium-auto"><input id="dob" type="date" name="dob" pattern="d{1,2}/d{1,2}/d{4}"
				required="" placeholder="MM/DD/YYYY" class="touched"></div>
		<div class="cell small-12 medium-7" style="padding-top:30px;">
			<p>Are you enrolled in any government, state, or federally funded prescription benefit program? This
				includes Medicare, Medicaid, Medigap, VA, DOD and Tricare?</p>
		</div>
		<div class="cell small-12 medium-auto">
			<div class="grid-x grid-margin-x">
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="govPaid" value="Y"
						required="" class="touched"><label style="display:inline-block;margin-right:10px;">Yes</label>
				</div>
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="govPaid" value="N"><label
						style="display:inline-block;margin-right:10px;">No</label></div>
			</div>
		</div>
		<div class="cell small-12 medium-7" style="padding-top:30px;">
			<p>Your card is not valid for prescriptions purchased under Medicaid, Medicare or any other state or federal
				employee-benefit programs. Should you begin receiving prescription benefits from such federal, state, or
				government funded program at any time, you will no longer be eligible to participate in this program. We
				may contact you by phone or mail periodically in order to verify that your eligibility for the program
				has not changed. Do you acknowledge your agreement with this statement?</p>
		</div>
		<div class="cell small-12 medium-auto">
			<div class="grid-x grid-margin-x">
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="medAck" value="Y"
						required="" class="touched"><label style="display:inline-block;margin-right:10px;">Yes</label>
				</div>
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="medAck" value="N"><label
						style="display:inline-block;margin-right:10px;">No</label></div>
			</div>
		</div>
		<div class="cell small-12 medium-7" style="padding-top:30px;">
			<p>You understand that the transactions processed using the card will be shared with Arbor Pharmaceuticals,
				the sponsor of the card. The information disclosed will include the date a transaction was processed
				using the card, the product dispensed by my pharmacist, and the amount that I will be reimbursed by
				Arbor Pharmaceuticals under the Horizant<sup>®</sup> Copay Savings Program. This information will be
				available to Arbor Pharmaceuticals and third parties working on behalf of Arbor Pharmaceuticals and will
				not be shared with anyone else.</p>
		</div>
		<div class="cell small-12 medium-auto">
			<div class="grid-x grid-margin-x">
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="permOptIn" value="Y"
						required="" class="touched"><label style="display:inline-block;margin-right:10px;">Yes</label>
				</div>
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="permOptIn"
						value="N"><label style="display:inline-block;margin-right:10px;">No</label></div>
			</div>
		</div>
		<div class="cell small-12 medium-7" style="padding-top:30px;">
			<p>You understand that the personal information you provide and information pertaining to the use of your
				coupon at the pharmacy will be shared with Arbor Pharmaceuticals and its third-party partners. You also
				authorize Arbor Pharmaceuticals and its partners to communicate with you in order to provide program
				benefits such as savings offers, information about your prescription, including refill reminders and new
				prescription requests, as well as program updates and alerts sent directly to your device. If you are a
				resident of California you may opt out of communications of the program entirely at any time by
				contacting CCPAPrivacy@ArborPharma.com or call the following toll-free telephone number 1-844-916-1298.
				You may also exercise your rights allowed under the CCPA by contacting those numbers. If you are not a
				resident of California, you may opt out of the program entirely at any time by contacting
				1-855-700-2990.</p>
		</div>
		<div class="cell small-12 medium-auto">
			<div class="grid-x grid-margin-x">
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="marketingOptIn" value="Y"
						required="" class="touched"><label style="display:inline-block;margin-right:10px;">Yes</label>
				</div>
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="marketingOptIn"
						value="N"><label style="display:inline-block;margin-right:10px;">No</label></div>
			</div>
		</div>
		<div class="cell small-12 medium-7" style="padding-top:30px;">
			<p>You understand and agree that you have not or will not submit a claim for reimbursement under any
				federal, state, or other government program for the covered drug.*</p>
		</div>
		<div class="cell small-12 medium-auto">
			<div class="grid-x grid-margin-x">
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="agree" value="1"
						required="" class="touched"><label style="display:inline-block;margin-right:10px;">I
						Agree</label></div>
				<div class="cell auto"><input type="radio" style="display:inline-block;" name="agree" value="2"><label
						style="display:inline-block;margin-right:10px;">I Disagree</label></div>
			</div>
		</div>
		<div class="cell small-12 medium-7" style="padding-top:30px; min-height:250px;">
			<p>Which condition are you diagnosed with? Check all that apply.</p>
		</div>
		<div class="cell small-12 medium-auto">
			<div class="grid-x grid-margin-x">
				<div class="cell small-12"><input class="condition1" type="checkbox"
						style="display:inline-block;margin-top:10px;float:left;" name="condition1" value="Y"><label
						style="display:inline-block;margin-right:10px;float:left;width:90%;">Moderate-to-severe primary
						Restless Legs Syndrome (RLS)</label></div>
				<div class="cell small-12"><input class="condition2" type="checkbox"
						style="display:inline-block;margin-top:10px;float:left;" name="condition2" value="Y"><label
						style="display:inline-block;margin-right:10px;float:left;width:90%;">Pain from damaged nerves
						(postherpetic pain) that follows healing of shingles (a painful rash that comes after a herpes
						zoster infection)</label></div>
				<div class="cell small-12"><input class="condition4" type="checkbox" style="display:inline-block;"
						name="condition4" value="Y"><label style="display:inline-block;margin-right:10px;">Prefer not to
						respond</label></div>
			</div>
		</div>
		<div class="cell small-12 medium-7" style="padding-top:30px;">
			<p>Preferred method of contact* </p>
		</div>
		<div class="cell small-12 medium-auto"><select name="contactMethod" required="" class="touched">
				<option value="E">Email</option>
				<option value="M">Home Address</option>
			</select></div>
	</div>
	<div class="grid-x">
		<div class="cell auto" style="margin-top:20px;"><button
				class="btn teal submitRequestForm pt-hz-button" value="Submit" type="submit"
				>Submit</button></div>
	</div>
	<div class="grid-x">
		<div class="cell auto">
			<div class="form-submit-error"></div>
		</div>
	</div>
	</form>
</div>`;

	jQuery('#signup-modal').html(copayTemplate2);

	// jQuery(".submit-copay").click(function (event) {

	// 	hcpSignupStep3();

	// });


}


function hcpSignupStep3() {



	var thanksTempate = `<div>
	<button class="close-button" data-close="" aria-label="Close modal" type="button">
        <span aria-hidden="true">×</span>
    </button>
	<h2 class="modal-heading">Welcome to the savings program!</h2>
	<p>Your savings card should download in a few seconds. If not, <a href="#">click here</a> to download your savings card.</p>
</div>`;

	window.location.href = '/hcp/thank-you';


}



function activateModal() {

	var copayTemplate2 = `<button class="close-button" data-close="" aria-label="Close modal" type="button">
	<span aria-hidden="true">×</span>
</button>
<h2 class="modal-heading">Activate your card</h2>
		<p>*indicates required fields.</p>
		<div class="activateContainer" style="display: block;">
			<form class="activateForm" id="activateForm" action="javascript:0">
				<div class="grid-x grid-padding-x align-middle">
					<div class="cell small-12 medium-7"><label for="">10-digit Horizant Savings Card ID number*</label></div>
					<div class="cell small-12 medium-auto"><input id="activate-card" name="card" type="text" maxlength="10"
							pattern="[0-9]+" required=""></div>
				</div>
				<div class="invalid-card-error"></div>
			</form>
			<form class="activateForm activateReplaceForm" id="activateRequestForm" action="javascript:0">
				<div class="hidden-card-holder"></div>
				<div class="grid-x grid-padding-x align-middle">
					<div class="cell small-12 medium-7"><label for="">To activate the card on behalf of a minor, you must be the
							parent, legal guardian, or representative and be at least 18 years of age*</label></div>
					<div class="cell small-12 medium-auto">
						<div class="grid-x grid-margin-x">
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="minor"
									required=""><label style="display:inline-block;margin-right:10px;">I Agree</label></div>
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="minor"><label
									style="display:inline-block;margin-right:10px;">I Disagree</label></div>
						</div>
					</div>
					<div class="cell small-12 medium-7"><label for="">Patient first name*</label></div>
					<div class="cell small-12 medium-auto"><input type="text" name="first" required=""
							placeholder="Patient first name">
						<div data-lastpass-icon-root="true"
							style="position: relative !important; height: 0px !important; width: 0px !important; float: left !important;">
						</div>
					</div>
					<div class="cell small-12 medium-7"><label for="">Patient last name* </label></div>
					<div class="cell small-12 medium-auto"><input type="text" name="last" required=""
							placeholder="Patient last name"></div>
					<div class="cell small-12 medium-7"><label for="">Mailing address 1*</label></div>
					<div class="cell small-12 medium-auto"><input type="text" name="address1" required=""
							placeholder="Mailing address 1"></div>
					<div class="cell small-12 medium-7"><label for="">Mailing address 2</label></div>
					<div class="cell small-12 medium-auto"><input type="text" name="address2" placeholder="Mailing address 2">
					</div>
					<div class="cell small-12 medium-7"><label for="">City*</label></div>
					<div class="cell small-12 medium-auto"><input type="text" name="city" required="" placeholder="City"></div>
					<div class="cell small-12 medium-7"><label for="">State/Territory*</label></div>
					<div class="cell small-12 medium-auto"><select name="state" required="">
							<option selected="" disabled="" hidden="">- Please Select -</option>
							<option value="AL">Alabama</option>
							<option value="AK">Alaska</option>
							<option value="AZ">Arizona</option>
							<option value="AR">Arkansas</option>
							<option value="CA">California</option>
							<option value="CO">Colorado</option>
							<option value="CT">Connecticut</option>
							<option value="DE">Delaware</option>
							<option value="DC">District Of Columbia</option>
							<option value="FL">Florida</option>
							<option value="GA">Georgia</option>
							<option value="HI">Hawaii</option>
							<option value="ID">Idaho</option>
							<option value="IL">Illinois</option>
							<option value="IN">Indiana</option>
							<option value="IA">Iowa</option>
							<option value="KS">Kansas</option>
							<option value="KY">Kentucky</option>
							<option value="LA">Louisiana</option>
							<option value="ME">Maine</option>
							<option value="MD">Maryland</option>
							<option value="MA">Massachusetts</option>
							<option value="MI">Michigan</option>
							<option value="MN">Minnesota</option>
							<option value="MS">Mississippi</option>
							<option value="MO">Missouri</option>
							<option value="MT">Montana</option>
							<option value="NE">Nebraska</option>
							<option value="NV">Nevada</option>
							<option value="NH">New Hampshire</option>
							<option value="NJ">New Jersey</option>
							<option value="NM">New Mexico</option>
							<option value="NY">New York</option>
							<option value="NC">North Carolina</option>
							<option value="ND">North Dakota</option>
							<option value="OH">Ohio</option>
							<option value="OK">Oklahoma</option>
							<option value="OR">Oregon</option>
							<option value="PA">Pennsylvania</option>
							<option value="RI">Rhode Island</option>
							<option value="SC">South Carolina</option>
							<option value="SD">South Dakota</option>
							<option value="TN">Tennessee</option>
							<option value="TX">Texas</option>
							<option value="UT">Utah</option>
							<option value="VT">Vermont</option>
							<option value="VA">Virginia</option>
							<option value="WA">Washington</option>
							<option value="WV">West Virginia</option>
							<option value="WI">Wisconsin</option>
							<option value="WY">Wyoming</option>
						</select></div>
					<div class="cell small-12 medium-7"><label for="">Zip*</label></div>
					<div class="cell small-12 medium-auto"><input type="text" name="zip" pattern="[0-9]+" maxlength="5"
							required="" placeholder="Zip"></div>
					<div class="cell small-12 medium-7"><label for="">Phone number*</label></div>
					<div class="cell small-12 medium-auto"><input class="phone" type="text" name="phone" required=""
							maxlength="14" placeholder="Phone number"></div>
					<div class="cell small-12 medium-7"><label for="">Email address*</label></div>
					<div class="cell small-12 medium-auto"><input type="email" name="email" required=""
							placeholder="Email address"></div>
					<div class="cell small-12 medium-7"><label for="">Date of birth* </label></div>
					<div class="cell small-12 medium-auto"><input id="dob" type="date" name="dob" pattern="d{1,2}/d{1,2}/d{4}"
							required="" placeholder="MM/DD/YYYY"></div>
					<div class="cell small-12 medium-7" style="padding-top:30px;">
						<p>Are you enrolled in any government, state, or federally funded prescription benefit program? This
							includes Medicare, Medicaid, Medigap, VA, DOD and Tricare?</p>
					</div>
					<div class="cell small-12 medium-auto">
						<div class="grid-x grid-margin-x">
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="govPaid" value="Y"
									required=""><label style="display:inline-block;margin-right:10px;">Yes</label></div>
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="govPaid"
									value="N"><label style="display:inline-block;margin-right:10px;">No</label></div>
						</div>
					</div>
					<div class="cell small-12 medium-7" style="padding-top:30px;">
						<p>Your card is not valid for prescriptions purchased under Medicaid, Medicare or any other state or
							federal employee-benefit programs. Should you begin receiving prescription benefits from such
							federal, state, or government funded program at any time, you will no longer be eligible to
							participate in this program. We may contact you by phone or mail periodically in order to verify
							that your eligibility for the program has not changed. Do you acknowledge your agreement with this
							statement?</p>
					</div>
					<div class="cell small-12 medium-auto">
						<div class="grid-x grid-margin-x">
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="medAck" value="Y"
									required=""><label style="display:inline-block;margin-right:10px;">Yes</label></div>
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="medAck"
									value="N"><label style="display:inline-block;margin-right:10px;">No</label></div>
						</div>
					</div>
					<div class="cell small-12 medium-7" style="padding-top:30px;">
						<p>You understand that the transactions processed using the card will be shared with Arbor
							Pharmaceuticals, the sponsor of the card. The information disclosed will include the date a
							transaction was processed using the card, the product dispensed by my pharmacist, and the amount
							that I will be reimbursed by Arbor Pharmaceuticals under the Horizant<sup>®</sup> Copay Savings
							Program. This information will be available to Arbor Pharmaceuticals and third parties working on
							behalf of Arbor Pharmaceuticals and will not be shared with anyone else.</p>
					</div>
					<div class="cell small-12 medium-auto">
						<div class="grid-x grid-margin-x">
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="permOptIn" value="Y"
									required=""><label style="display:inline-block;margin-right:10px;">Yes</label></div>
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="permOptIn"
									value="N"><label style="display:inline-block;margin-right:10px;">No</label></div>
						</div>
					</div>
					<div class="cell small-12 medium-7" style="padding-top:30px;">
						<p>You understand that the personal information you provide and information pertaining to the use of
							your coupon at the pharmacy will be shared with Arbor Pharmaceuticals and its third-party partners.
							You also authorize Arbor Pharmaceuticals and its partners to communicate with you about this
							program. You agree to be contacted by mail, email, and at the phone number you provided to leave
							voice messages. You may opt out of communications of the program entirely at any time by calling
							855-700-2990.</p>
					</div>
					<div class="cell small-12 medium-auto">
						<div class="grid-x grid-margin-x">
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="marketingOptIn"
									value="Y" required=""><label style="display:inline-block;margin-right:10px;">Yes</label>
							</div>
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="marketingOptIn"
									value="N"><label style="display:inline-block;margin-right:10px;">No</label></div>
						</div>
					</div>
					<div class="cell small-12 medium-7" style="padding-top:30px;">
						<p>You understand and agree that you have not or will not submit a claim for reimbursement under any
							federal, state, or other government program for the covered drug.*</p>
					</div>
					<div class="cell small-12 medium-auto">
						<div class="grid-x grid-margin-x">
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="agree" value="1"
									required=""><label style="display:inline-block;margin-right:10px;">I Agree</label></div>
							<div class="cell auto"><input type="radio" style="display:inline-block;" name="agree"
									value="2"><label style="display:inline-block;margin-right:10px;">I Disagree</label></div>
						</div>
					</div>
					<div class="cell small-12 medium-7" style="padding-top:30px; min-height:250px;">
						<p>Which condition are you diagnosed with? Check all that apply.</p>
					</div>
					<div class="cell small-12 medium-auto">
						<div class="grid-x grid-margin-x">
							<div class="cell small-12"><input class="condition1" type="checkbox"
									style="display:inline-block;margin-top:10px;float:left;" name="condition1" value="Y"><label
									style="display:inline-block;margin-right:10px;float:left;width:90%;">Moderate-to-severe
									primary Restless Legs Syndrome (RLS)</label></div>
							<div class="cell small-12"><input class="condition2" type="checkbox"
									style="display:inline-block;margin-top:10px;float:left;" name="condition2" value="Y"><label
									style="display:inline-block;margin-right:10px;float:left;width:90%;">Pain from damaged
									nerves (postherpetic pain) that follows healing of shingles (a painful rash that comes after
									a herpes zoster infection)</label></div>
							<div class="cell small-12"><input class="condition4" type="checkbox" style="display:inline-block;"
									name="condition4" value="Y"><label style="display:inline-block;margin-right:10px;">Prefer
									not to respond</label></div>
						</div>
					</div>
					<div class="cell small-12 medium-7" style="padding-top:30px;">
						<p>Preferred method of contact* </p>
					</div>
					<div class="cell small-12 medium-auto"><select name="contactMethod" required="">
							<option value="E">Email</option>
							<option value="M">Home Address</option>
						</select></div>
				</div>
				<div class="grid-x">
					
					<div class="cell" style="margin-top:20px;"><button
							class="btn teal submitActivateForm pt-hz-button" value="Submit" type="submit"
							style="float:none;max-width:50%;margin:10px auto;">Submit</button></div>
				
				</div>
				<div class="grid-x">
					<div class="cell auto">
						<div class="form-submit-error"></div>
					</div>
				</div>
			</form>
		</div>
</div>`;

	jQuery('#signup-modal').html(copayTemplate2);

	jQuery( "#activate-card" ).focusout(function() {
        submitActivate();
    });

	jQuery( "#activate-card" ).change(function() {
		console.log('change')
		jQuery( "#activate-card" ).addClass('touched');
	  });

	  
    jQuery( ".submitActivateForm" ).click(function( event ) {
        submitRequest('#activateRequestForm'); 
      });


	// jQuery(".submit-copay").click(function (event) {

	// 	signupStep3();

	// });


}

function replaceModal() {

	var copayTemplate2 = `<button class="close-button" data-close="" aria-label="Close modal" type="button">
	<span aria-hidden="true">×</span>
</button>
<h2 class="modal-heading">Replace your card</h2>
<form class="replaceForm" id="replaceForm" action="javascript:0" style="display: block;">
<p>Please provide the following information to replace a card </p>
<div class="grid-x">
	<div class="cell small-12 medium-6"><label>Date of birth* </label></div>
	<div class="cell small-12 medium-6"><input id="dob" type="date" name="dob" pattern="d{1,2}/d{1,2}/d{4}" required="" placeholder="MM/DD/YYYY"></div>
	<div class="cell small-12 medium-6"><label>Zip code* </label></div>
	<div class="cell small-12 medium-6"><input type="text" name="zip" required=""></div>
		<p>And one of the following: </p>
		<div data-lastpass-icon-root="true"
			style="position: relative !important; height: 0px !important; width: 0px !important; float: left !important;">
		</div>
	</div>
</div>
<div class="grid-x">
	<div class="cell small-12 medium-6"><label>10-digit card ID</label></div>
	<div class="cell small-12 medium-6"><input type="text" name="card" maxlength="10" pattern="[0-9]+"></div>
		<div class="cell show-for-medium medium-6 text-center"></div>
		<div class="cell small-12 medium-6 text-center"> or </div>
		<div class="cell small-12 medium-6"><label>Phone number</label></div>
		<div class="cell small-12 medium-6"><input class="phone" type="text" name="phone" maxlength="14" label="Phone Number"></div>
	</div>
	
</div>
<div class="grid-x">
	<div class="cell" style="margin-top:20px;"><button
			class="btn teal submitReplaceForm pt-hz-button" type="Submit" value="Submit"
			style="">Submit</button></div>

</div>
<div class="form-submit-error"></div>
</form>
</div>`;

	jQuery('#signup-modal').html(copayTemplate2);

	jQuery( ".submitReplaceForm" ).click(function( event ) {
        submitReplace();
      });


	// jQuery(".submit-copay").click(function (event) {

	// 	signupStep3();

	// });


}